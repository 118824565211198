import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)


// 解决路由地址重复的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },

  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "login" */ '../views/Home.vue'),
    children: [
      {
        path: '/client',
        name: 'Client',
        component: () => import(/* webpackChunkName: "login" */ '../views/Client.vue'),
        meta: {
          title: '终端门店管理',
          keepAlive: true
        }
      },
      {
        path: '/group',
        name: 'Group',
        component: () => import(/* webpackChunkName: "login" */ '../views/Group.vue'),
        meta: {
          title: '集团客户管理',
          keepAlive: true
        }
      },
      {
        path: '/order',
        name: 'Order',
        component: () => import(/* webpackChunkName: "login" */ '../views/Order.vue'),
        meta: {
          title: '工单管理',
          keepAlive: true
        }
      },
      {
        path: '/ques',
        name: 'Ques',
        component: () => import(/* webpackChunkName: "login" */ '../views/Ques.vue'),
        meta: {
          title: '回访管理',
          keepAlive: true
        }
      },
      {
        path: '/user',
        name: 'User',
        component: () => import(/* webpackChunkName: "login" */ '../views/User.vue'),
        meta: {
          title: '用户管理',
          keepAlive: true
        }
      },
      {
        path: '/visitor',
        name: 'Visitor',
        component: () => import(/* webpackChunkName: "login" */ '../views/Visitor.vue'),
        meta: {
          title: '访客管理',
          keepAlive: true
        }
      },

      {
        path: '/question',
        name: 'Question',
        component: () => import(/* webpackChunkName: "login" */ '../views/Question.vue'),
        meta: {
          title: '问题管理',
          keepAlive: true
        }
      },
      {
        path: '/tag',
        name: 'Tag',
        component: () => import(/* webpackChunkName: "login" */ '../views/Tag.vue'),
        meta: {
          title: 'Tag管理',
          keepAlive: true
        }
      },
      {
        path: '/template',
        name: 'Template',
        component: () => import(/* webpackChunkName: "login" */ '../views/Template.vue'),
        meta: {
          title: '问卷模板管理',
          keepAlive: true
        }
      },
      {
        path: '/createtemplate',
        name: 'Createtemplate',
        component: () => import(/* webpackChunkName: "login" */ '../views/CreateTemplate.vue'),
        meta: {
          title: '新增问卷模板',
          keepAlive: true
        }
      },
      {
        path: '/completeness',
        name: 'Completeness',
        component: () => import(/* webpackChunkName: "login" */ '../views/Completeness.vue'),
        meta: {
          title: '任务完成度',
          keepAlive: true
        }
      },

      {
        path: '/statistical',
        name: 'Statistical',
        component: () => import(/* webpackChunkName: "login" */ '../views/Statistical.vue'),
        meta: {
          title: '回访报告评分统计',
          keepAlive: true
        }
      },
      {
        path: '/userdetails',
        name: 'Userdetails',
        component: () => import(/* webpackChunkName: "login" */ '../views/UserDetails.vue'),
        meta: {
          title: '用户详情',
          keepAlive: false
        }
      },

      {
        path: '/orderdetails',
        name: 'Orderdetails',
        component: () => import(/* webpackChunkName: "login" */ '../views/OrderDetails.vue'),
        meta: {
          title: '工单详情',
          keepAlive: false
        }
      },
      {
        path: '/clientdetails',
        name: 'Clientdetails',
        component: () => import(/* webpackChunkName: "login" */ '../views/ClientDetails.vue'),
        meta: {
          title: '客户详情',
          keepAlive: false
        }
      },
      {
        path: '/questiondetails',
        name: 'Questiondetails',
        component: () => import(/* webpackChunkName: "login" */ '../views/QuestionDetails.vue'),
        meta: {
          title: '问题详情',
          keepAlive: false
        }
      },
      {
        path: '/questiongroup',
        name: 'Questiongroup',
        component: () => import(/* webpackChunkName: "login" */ '../views/QuestionGroup.vue'),
        meta: {
          title: '问题组管理',
          keepAlive: false
        }
      },
      {
        path: '/currenttasks',
        name: 'Currenttasks',
        component: () => import(/* webpackChunkName: "login" */ '../views/CurrentTasks.vue'),
        meta: {
          title: '当月应拜访客户列表',
          keepAlive: true
        }
      },
      {
        path: '/workreport',
        name: 'Workreport',
        component: () => import(/* webpackChunkName: "login" */ '../views/WorkReport.vue'),
        meta: {
          title: '工作完成率报告',
          keepAlive: false
        }
      }, {
        path: '/questionreport',
        name: 'Questionreport',
        component: () => import(/* webpackChunkName: "login" */ '../views/QuestionReport.vue'),
        meta: {
          title: '问题分析报告',
          keepAlive: true
        }
      }, {
        path: '/orderreport',
        name: 'Orderreport',
        component: () => import(/* webpackChunkName: "login" */ '../views/OrderReport.vue'),
        meta: {
          title: '工单数据统计',
          keepAlive: false
        }
      },
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "login" */ '../views/404.vue'),
  },

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to:', to.path);
  if (to.name === "Client" || to.name === "Order" || to.name === "User" || to.name === "Visitor" || to.name === "Ques" || to.name === "Question" || to.name === "Completeness" || to.name === "Statistical" || to.name === "Questionreport") {
    to.meta.keepAlive = true;
  } else {
    to.meta.keepAlive = false;
  }
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})



export default router
